export default {
    title: 'CDC Stock Accuracy',
    location: '/business/fuo/cdc-stock-accuracy',
    tabs: [
        {
            name: 'By Country',
            link: `/business/fuo/cdc-stock-accuracy`
        },
        {
            name: 'Trend',
            link: `/business/fuo/cdc-stock-accuracy/:market`
        },
        // {
        //     name: 'Top 10',
        //     link: '/business/business-order-quality/'
        // }
    ]
}